<template>
  <div>
    <model title="v1.0.2" tag="2024-01-02">
      <div class="lh-icon-logo"><span>lhUI</span></div>
      <div class="foot">
        Hello world! <br/> 
        第一个正式版本<br/>
        author:两行
      </div>
    </model>
  </div>
</template>

<script>
import Model from '../../components/Model.vue'
export default {
  components: { Model },
  name:"V1.0.2"
}
</script>

<style lang="less" scoped>
.lh-icon-logo{
  font-size: 200px;
  span{
    font-size: 100px;
    font-weight: 700;
  }
}
.foot{
  font-weight: 700;
}
</style>